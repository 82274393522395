<div class="blocks-container blockchain-blocks-container" [class.time-ltr]="timeLtr"
  [style.left]="static ? (offset || 0) + 'px' : null"
  *ngIf="static || (loadingBlocks$ | async) === false; else loadingBlocksTemplate">
  <div *ngFor="let block of blocks; let i = index; trackBy: trackByBlocksFn">
    <ng-container *ngIf="connected && block && !block.loading && !block.placeholder; else placeholderBlock">
      <div [attr.data-cy]="'bitcoin-block-offset-' + offset + '-index-' + i"
        class="text-center bitcoin-block mined-block blockchain-blocks-offset-{{ offset }}-index-{{ i }}"
        id="bitcoin-block-{{ block.height }}" [ngStyle]="blockStyles[i]"
        [class.blink-bg]="isSpecial(block.height)">
        <a draggable="false" [routerLink]="['/block/' | relativeUrl, block.id]" [state]="{ data: { block: block } }"
          class="blockLink" [ngClass]="{'disabled': (this.stateService.blockScrolling$ | async)}">&nbsp;</a>
        <div [attr.data-cy]="'bitcoin-block-' + i + '-height'" class="block-height">
          <a [routerLink]="['/block/' | relativeUrl, block.id]" [state]="{ data: { block: block } }">{{ block.height
            }}</a>
        </div>
        <div class="block-body">
          <div *ngIf="block?.extras; else emptyfees" [attr.data-cy]="'bitcoin-block-offset=' + offset + '-index-' + i + '-fees'" class="fees">
            ~{{ block?.extras?.medianFee | number:feeRounding }} <ng-container
              i18n="shared.sat-vbyte|sat/vB">sat/vB</ng-container>
          </div>
          <ng-template #emptyfees>
            <div [attr.data-cy]="'bitcoin-block-offset=' + offset + '-index-' + i + '-fees'" class="fees">
              &nbsp;
            </div>
          </ng-template>
          <div [attr.data-cy]="'bitcoin-block-' + offset + '-index-' + i + '-fee-span'" class="fee-span"
            *ngIf="block?.extras?.feeRange; else emptyfeespan">
            {{ block?.extras?.feeRange?.[0] | number:feeRounding }} - {{
            block?.extras?.feeRange[block?.extras?.feeRange?.length - 1] | number:feeRounding }} <ng-container
              i18n="shared.sat-vbyte|sat/vB">sat/vB</ng-container>
          </div>
          <ng-template #emptyfeespan>
            <div [attr.data-cy]="'bitcoin-block-offset=' + offset + '-index-' + i + '-fees'" class="fee-span">
              &nbsp;
            </div>
          </ng-template>
          <div [attr.data-cy]="'bitcoin-block-' + offset + '-index-' + i + '-total-fees'" *ngIf="showMiningInfo"
            class="block-size">
            <app-amount [satoshis]="block.extras?.totalFees ?? 0" digitsInfo="1.2-3" [noFiat]="true"></app-amount>
          </div>
          <div [attr.data-cy]="'bitcoin-block-' + offset + '-index-' + i + 'block-size'" *ngIf="!showMiningInfo"
            class="block-size" [innerHTML]="'&lrm;' + (block.size | bytes: 2)"></div>
          <div [attr.data-cy]="'bitcoin-block-' + i + '-transactions'" class="transaction-count">
            <ng-container
              *ngTemplateOutlet="block.tx_count === 1 ? transactionsSingular : transactionsPlural; context: {$implicit: block.tx_count | number}"></ng-container>
            <ng-template #transactionsSingular let-i i18n="shared.transaction-count.singular">{{ i }} transaction</ng-template>
            <ng-template #transactionsPlural let-i i18n="shared.transaction-count.plural">{{ i }} transactions</ng-template>
          </div>
          <div [attr.data-cy]="'bitcoin-block-' + offset + '-index-' + i + '-time'" class="time-difference">
            <app-time kind="since" [time]="block.timestamp" [fastRender]="true"></app-time></div>
        </div>
        <div class="animated" [class]="showMiningInfo ? 'show' : 'hide'" *ngIf="block.extras?.pool != undefined">
          <a [attr.data-cy]="'bitcoin-block-' + offset + '-index-' + i + '-pool'" class="badge badge-primary"
            [routerLink]="[('/mining/pool/' + block.extras.pool.slug) | relativeUrl]">
            {{ block.extras.pool.name}}</a>
        </div>
      </div>
    </ng-container>
    <ng-template #placeholderBlock>
      <ng-container *ngIf="block && block.placeholder && connected && !loadingTip; else loadingBlock">
        <div [attr.data-cy]="'bitcoin-block-' + offset + '-index-' + i"
          class="text-center bitcoin-block mined-block placeholder-block blockchain-blocks-{{ i }}"
          id="bitcoin-block-{{ block.height }}" [ngStyle]="blockStyles[i]">
 
        </div>
      </ng-container>
    </ng-template>
    <ng-template #loadingBlock>
      <ng-container *ngIf="!connected || loadingTip || (block && block.loading)">
        <div class="flashing loading">
          <div class="text-center bitcoin-block mined-block" id="bitcoin-block-{{ block.height }}"
            [ngStyle]="convertStyleForLoadingBlock(blockStyles[i])"></div>
        </div>
      </ng-container>
    </ng-template>
  </div>
  <div [hidden]="!arrowVisible" id="arrow-up" [style.transition]="arrowTransition"
    [ngStyle]="{'left': arrowLeftPx + 'px' }"></div>
</div>

<ng-template #loadingBlocksTemplate>
  <div class="blocks-container" [class.time-ltr]="timeLtr">
    <div class="flashing">
      <div *ngFor="let block of emptyBlocks; let i = index; trackBy: trackByBlocksFn">
        <div class="text-center bitcoin-block mined-block" id="bitcoin-block-{{ block.height }}"
          [ngStyle]="emptyBlockStyles[i]"></div>
      </div>
    </div>
  </div>
</ng-template>
